<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">
        {{ inputs.language === 'en' ? 'Experiment 6: Question 6' : 'Expérience 6: Question 6' }}
      </h2>

      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'Why do we use a hot water bath to heat up the reaction instead of a heating mantle?'
            : "Pourquoi utilisons-nous un bain d'eau chaude pour chauffer la réaction plutôt qu'une mante chauffante?"
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab6Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'To heat less so the solvent does not evaporate, and the volume of solvent remains constant',
          value: 'heatLess',
        },
        {
          text: 'To heat more because the reaction is highly endothermic',
          value: 'heatMore',
        },
        {
          text: 'To heat gradually less and less as the water cools down',
          value: 'graduallyLess',
        },
        {
          text: 'The heat applied is more equally distributed with a water bath',
          value: 'equally',
        },
        {
          text: 'Because the solvent used in the reaction is flammable',
          value: 'flammable',
        },
      ],
      optionsFr: [
        {
          text: "Pour chauffer moins afin que le solvant ne s'évapore pas et que le volume de solvant demeure constant",
          value: 'heatLess',
        },
        {
          text: 'Pour chauffer plus parce que cette réaction est grandement endothermique',
          value: 'heatMore',
        },
        {
          text: "Pour chauffer de moins en moins à mesure que l'eau refroidit",
          value: 'graduallyLess',
        },
        {
          text: 'Pour appliquer une chaleur qui est plus uniforme',
          value: 'equally',
        },
        {
          text: 'Parce que le solvant utilisé pour la réaction est inflammable',
          value: 'flammable',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
